<template lang="">
    <header class="header -single">
        <div class="container d-flex justify-content-center">
            <router-link to="/" class="header-logo">
                <img src="/img/logo/logo.png" srcset="/img/logo/logo@2x.png 2x" alt="logo">
            </router-link>
        </div>
    </header>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>