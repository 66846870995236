export const runtimeConfiguration = {
  install: (app, options) => {
    app.config.globalProperties.$runtimeConfiguration = options

    app.provide("runtimeConfiguration", options)
  }
}

export const loadRuntimeConfiguration = async () => {
  const response = await fetch('/config.json')
  const value = await response.json()

  return value
}
