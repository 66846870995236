// @ts-nocheck

// Product Routes
const ProductsView = () => import("../views/admin/Product/ListView.vue")
const ProductCreateView = () => import("../views/admin/Product/CreateView.vue")
const ProductEditView = () => import("../views/admin/Product/EditView.vue")
// Project Routes
const ProjectsView = () => import("../views/admin/Project/ListView.vue")
const ProjectCreateView = () => import("../views/admin/Project/CreateView.vue")
const ProjectEditView = () => import("../views/admin/Project/EditView.vue")
// Customer Routes
const CustomersView = () => import("../views/admin/Customers/ListView.vue")
const CustomerCreateView = () => import("../views/admin/Customers/CreateView.vue")
const CustomerEditView = () => import("../views/admin/Customers/EditView.vue")
// Admin Routes
const ConfigsView = () => import("../views/admin/ConfigsView.vue")
// Logs Routes
const LogsView = () => import('../views/admin/LogsView.vue')
// Payment Method
const PaymentMethods = () => import('../views/admin/PaymentMethod.vue')

interface IMetaObject {
  layout?: string,
  is_auth_protected?: boolean,
  only_admin?: boolean,
}

interface IRoute {
  path: string,
  name: string,
  component: object,
  meta: IMetaObject,
}

const routes: Array<IRoute> = [
  {
    path: '/admin',
    name: 'AdminPage',
    component: ProductsView,
    meta: {
      layout: 'LayoutAdmin',
      protected: true,
      only_admin: true
    }
  },
  {
    path: '/admin/server_logs',
    name: 'AdminLogsView',
    component: LogsView,
    meta: {
      layout: "LayoutAdmin",
      protected: true,
      only_admin: true,
    }
  },
  {
    path: '/admin/configs',
    name: 'AdminManageConfigs',
    component: ConfigsView,
    meta: {
      layout: "LayoutAdmin",
      protected: true,
      only_admin: true,
    }
  },
  {
    path: "/admin/products",
    name: "AdminManageProducts",
    component: ProductsView,
    meta: {
      layout: "LayoutAdmin",
      protected: true,
      only_admin: true,
    }
  },
  {
    path: "/admin/products/create",
    name: "AdminManageProductsCreate",
    component: ProductCreateView,
    meta: {
      layout: "LayoutAdmin",
      protected: true,
      only_admin: true,
    }
  },
  {
    path: "/admin/products/edit/:id",
    name: "AdminManageProductsEdit",
    component: ProductEditView,
    meta: {
      layout: "LayoutAdmin",
      protected: true,
      only_admin: true,
    }
  },
  {
    path: "/admin/projects",
    name: "AdminManageProjects",
    component: ProjectsView,
    meta: {
      layout: "LayoutAdmin",
      protected: true,
      only_admin: true,
    }
  },
  {
    path: "/admin/projects/create",
    name: "AdminManageProjectsCreate",
    component: ProjectCreateView,
    meta: {
      layout: "LayoutAdmin",
      protected: true,
      only_admin: true,
    }
  },
  {
    path: "/admin/projects/edit/:id",
    name: "AdminManageProjectsEdit",
    component: ProjectEditView,
    meta: {
      layout: "LayoutAdmin",
      protected: true,
      only_admin: true,
    } 
  },
  {
    path: "/admin/customers",
    name: "AdminManageCustomers",
    component: CustomersView,
    meta: {
      layout: "LayoutAdmin",
      protected: true,
      only_admin: true,
    }
  },
  {
    path: "/admin/customers/create",
    name: "AdminManageCustomersCreate",
    component: CustomerCreateView,
    meta: {
      layout: "LayoutAdmin",
      protected: true,
      only_admin: true,
    }
  },
  {
    path: "/admin/customers/edit/:id",
    name: "AdminManageCustomersEdit",
    component: CustomerEditView,
    meta: {
      layout: "LayoutAdmin",
      protected: true,
      only_admin: true,
    }
  },
  {
    path: '/admin/credit-cards',
    name: 'AdminCreditCardsPage',
    component: PaymentMethods,
    meta: {
      layout: 'LayoutAdmin',
      is_auth_protected: true,
      only_admin: true
    }
  }
]

export default routes;