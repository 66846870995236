import { defineStore } from 'pinia'

interface IProject {
  id: number
  name: string
}

interface IInterface {
  projects: IProject[]
  selected_project?: number
}

export const useInterfaceStore = defineStore('interface', {
  state: (): IInterface => {
    return { 
      projects: [],
      selected_project: 0 
    }
  },
  getters: {
    getProjects: state => {
      return state.projects
    },
    getSelectedProject: state => {
      return state.selected_project
    }
  },
  actions: {
    setProjects(projects: IProject[]): void {
      this.projects = projects
    },
    setSelectedProject(project_id: number): void {
      this.selected_project = project_id
    }
  }
})