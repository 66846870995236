<template>
  <HeaderComponent/>

  <main>
    <section class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="stat-card card">
            <ul class="w-100">
              <li class="mb-3">
                <h3>My account</h3>
              </li>
              <li 
                v-for="(link, i) in getLinks" 
                :key="i"
                :class="{
                  'disabled': link.disabled,
                  'current': isCurrent(link.route)
                }"
                class="mb-1"
              >
                <router-link :to="link.route">
                  <button class="">
                    {{ link.label }}
                  </button>
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-9" id="layout-main-content">
          <slot></slot>
        </div>
      </div>
    </section>
  </main>

  <FooterComponent/>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import FooterComponent from "./Footer/FooterComponent.vue";
import HeaderComponent from "./Header/HeaderComponent.vue";
import { useCustomerStore } from "../stores/customer";

const route = useRoute();
const store = useCustomerStore();
const id = store.getId
const state = ({
  links: [
    {
      route: "/my-account",
      label: "Account Details",
      icon: "fa-solid fa-user",
      disabled: false,
    },
    {
      route: `/payment_methods/${id}`,
      label: "Payment Methods",
      icon: "",
      disabled: false,
    }, 
    {
      route: `/change-password`,
      label: "Change Password",
      icon: "",
      disabled: false,
    }
  ]
})

function isCurrent(link){
  if(link == "/") return false;
  return route.path.includes(link);
}

const getLinks = computed(() => {
  return state.links.filter((l) => {
    return l.disabled == false
  })
})
</script>

<style scoped>
.btn.grey {
  background: #f8f8fa;
  border: solid 1px #d0d5dd;
}

.current button{
  font-weight: bold;
  color: #184ABC
}

</style>