export const getUri = (path: string): string => {
  return `${window.location.protocol}//${window.location.host}${path}`
}

export const formattedCurrency = (amount: number | null, currency: 'usd' | 'eur' = 'eur') => {
  const currencySymbol: Record<string, string> = {
    usd: '$',
    eur: '€'
  }

  const sign = currencySymbol[currency] || '€'
  const price = (amount ?? 0).toFixed(2)

  return `${sign}${price}`
}