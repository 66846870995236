import { createRouter, createWebHistory } from "vue-router";

import admin_routes from "./admin_routes"
import public_routes from "./public_routes"
import auth_protected_routes from "./auth_protected_routes";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...public_routes, 
    ...auth_protected_routes,
    ...admin_routes,
  ],
});

export default router;
