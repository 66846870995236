<template>
    <HeaderComponent/>
  
    <main>
      <section class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="stat-card card">
              <ul 
                v-if="store._customer && isAdmin" 
                class="w-100" 
                style="margin-top: 15px;"
              >
                <li class="mb-3">
                  <h3>Manage</h3>
                </li>
                <li 
                  v-for="(link, i) in state.links" 
                  :key="i"
                  :class="{
                    'disabled': link.disabled,
                    'current': isCurrent(link.route)
                  }"
                  class="mb-1"
                >
                  <router-link :to="link.route" :id="`manage-${link.name}`"> 
                    <button class="">
                      {{ link.label }}
                    </button>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
  
          <div class="col-lg-9" id="layout-main-content">
            <slot></slot>
          </div>
        </div>
      </section>
    </main>
  
    <FooterComponent/>
  </template>
  
  <script setup>
  import { computed } from "vue";
  import { useRoute } from "vue-router";
  import FooterComponent from "./Footer/FooterComponent.vue";
  import HeaderComponent from "./Header/HeaderComponent.vue";
  import { useCustomerStore } from "../stores/customer";
  
  const route = useRoute();
  const store = useCustomerStore();
  const state = ({
    links: [
      {
        route: "/admin/products",
        label: "Products",
        icon: "",
        disabled: false,
        name: 'products'
      },
      {
        route: "/admin/projects",
        label: "Projects",
        icon: "",
        disabled: false,
        name: 'projects'
      },
      {
        route: "/admin/customers",
        label: "Customers",
        icon: "",
        disabled: false,
        name: 'customers'
      },
      {
        route: '/admin/credit-cards',
        label: 'Credit Cards',
        icon: '',
        disabled: false,
        name: 'admin-credit-cards'
      },
      {
        route: "/admin/configs",
        label: "Configs",
        icon: "",
        disabled: false,
        name: 'configs'
      },
      {
        route: "/admin/server_logs",
        label: "Server logs",
        icon: "",
        disabled: false,
        name: 'server-logs'
      }
    ]
  })
  
  const isAdmin = computed(() => {
    const customer = store._customer;
    if(customer.role == undefined)
      return false;
    else
      return customer.role == "admin";
  })
  
  function isCurrent(link) {
    if(link == "/") return false;

    if(route.path === "/admin" && link === "/admin/products") {
      return true;
    }
    return route.path.includes(link);
  }
  
  </script>
  
  <style scoped>
  .btn.grey {
    background: #f8f8fa;
    border: solid 1px #d0d5dd;
  }
  
  .current button{
    font-weight: bold;
    color: #184ABC
  }
  
  </style>