<template>
  <div id="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-header-container">
        <div class="modal-header">
          <slot name="header">
            default header
          </slot>
        </div>
      </div>

      <div class="modal-container">
        <div class="modal-body">
          <slot name="body">
            default body
          </slot>
        </div>

        <div class="modal-bottom mt-5 ">

          <slot name="bottom">
            <div v-if="getType == 'info'">
              <button @click="$emit('close')">
                Close
              </button>
            </div>

            <div v-else-if="getType == 'confirm'" class="d-flex justify-content-end">
              <button @click="$emit('decline')" class="btn -filled -sm -red me-3">
                <slot name="decline-btn-text">
                  Cancel
                </slot>
              </button>

              <button @click="$emit('confirm')" class="btn -filled -sm -blue">
                <slot name="confirm-btn-text">
                  Confirm
                </slot>
              </button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: String
})

const getType = computed(() => {
  return props.type || 'info'
})
</script>

<style lang="css">
#modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-header-container {
  width: 500px;
  margin: 0px auto;
  padding: 10px 30px;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  background: #3d3d3d;
  color: #fff;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
</style>