import axios from "axios";
import { useToast } from "vue-toastification";
import { loadRuntimeConfiguration } from "../plugins/runtime-configuration";
import { getUri } from "../utils"
import { useRouter } from "vue-router";

const toast = useToast();
const router = useRouter();
const config = await loadRuntimeConfiguration()
const BASE_URL = import.meta.env.PROD ? getUri(config.BACKEND_URL || '/api/') : config.BACKEND_URL

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
})

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("customer")
        router.push("/")
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Something went wrong, please try again later')
      }
    } else if (!error.response || error.code == "ERR_NETWORK") {
      toast.error("Please check your internet connection.")
    }

    return Promise.reject(error)
  }
)

export default instance;