import { createApp, inject } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import LayoutDefault from "./layouts/LayoutDefault.vue";
import LayoutSimple from "./layouts/LayoutSimple.vue";
import LayoutMyAccount from "./layouts/LayoutMyAccount.vue";
import LayoutAdmin from "./layouts/LayoutAdmin.vue";
import ModalComponent from './components/ModalComponent.vue'
import LayoutServices from "./layouts/LayoutServices.vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createLogto } from '@logto/vue';
import { vMaska } from 'maska' 
import './assets/base.css'
import { loadRuntimeConfiguration, runtimeConfiguration } from "./plugins/runtime-configuration";

const loadRuntimeConfigurationOptions = await loadRuntimeConfiguration()

const default_resource = loadRuntimeConfigurationOptions.DEFAULT_LOGTO_RESOURCE || 'https://default.logto.app/api'

const config = {
  endpoint: loadRuntimeConfigurationOptions.LOGTO_ENDPOINT,
  appId: loadRuntimeConfigurationOptions.LOGTO_SPA_APP_ID,
  resources: [
    default_resource,
    'http://users.omniportal.app'
  ],
};

const app = createApp(App);
const pinia = createPinia();

app.directive('maska', vMaska)
app.use(router);
app.use(pinia);
app.use(runtimeConfiguration, loadRuntimeConfigurationOptions)
app.use(createLogto, config);
app.use(Toast, {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: false,
  icon: true,
  rtl: false,
});

import { useCustomerStore } from "./stores/customer";
import { useToast } from "vue-toastification";
import customerService from "./services/customers";

router.beforeEach(async (to, from, next) => {
  const is_auth_protected = to.meta.is_auth_protected == true;
  const is_only_admin = to.meta.only_admin == true;
  const customer = useCustomerStore().getCustomer
  const toast = useToast();

  if(is_only_admin || is_auth_protected){
    if (customer == null) {
    //   console.log(store.getCustomer)
    //   console.log(localStorage.getItem('logto:ky8slonh57amjhotrftzc:accessToken'))
      toast.error('[route guard] Not authenticated')
      return next('/')
    }
    else if(is_only_admin && customer.role !== 'admin') {
      toast.error('[route guard] Without permissions')
      return next('/')
      // return next()
    }
    else
      return next()
  }
  
  return next()
})

app.component("LayoutDefault", LayoutDefault);
app.component("LayoutSimple", LayoutSimple);
app.component("LayoutMyAccount", LayoutMyAccount);
app.component("LayoutAdmin", LayoutAdmin);
app.component("LayoutServices", LayoutServices);
app.component('Modal', ModalComponent)

app.mount("#app");
