<template>
  <Layout>
    <Suspense>
      <RouterView />
    </Suspense>
  </Layout>
</template>

<script setup>
import customerService from "./services/customers";
import projectsService from "./services/projects";
import { useLogto } from "@logto/vue";
import Layout from "./layouts/Layout.vue";
import { watch, onMounted, reactive } from "vue";
import { useCustomerStore } from "@/stores/customer";
import { useInterfaceStore } from "@/stores/interface"
import { RouterView } from "vue-router";
import Tracker from '@openreplay/tracker';

const customerStore = useCustomerStore()
const interfaceStore = useInterfaceStore()

const { getAccessToken } = useLogto();
let jwt
let tracker

if (import.meta.env.PROD) {
  tracker = new Tracker({
    projectKey: "Hharx55csA1v11Lj80aG",
    ingestPoint: "https://replay.omni-cloud.io/ingest",
  });
  tracker.start();
}

async function getCustomer () {
  const response = await customerService.getMe(jwt)
  return response
}

const getProjects = async (jwt) => {
  const response = await projectsService.getFromUser(jwt, id)
  return response
}

async function fetchUserSettings() {
  const customer = customerStore.getCustomer
  const projects = interfaceStore.getProjects

  try {
    if (customer === null) {
      const customer_response = await getCustomer(jwt)
    
      if (import.meta.env.PROD) tracker.setUserID(customer_response.email);
      customerStore.setCustomer(customer_response)

      if (projects.length === 0) {
        const projects_response = await getProjects(jwt, customer_response.id)
        interfaceStore.setProjects(projects_response)

        if(projects_response.length > 0) {
          if (customer.default_project !== null)
            interfaceStore.setSelectedProject(customer.default_project)
          else
            interfaceStore.setSelectedProject(projects_response[0].id)
        }
      }
    }
  }
  catch (error) {
    console.log(error)
  }
}

watch(() => customerStore.getCustomer , async (new_value, old_value) => {
  if(old_value == null) {
    await fetchUserSettings()
  }
})

onMounted(async () => {
  jwt = await getAccessToken("http://users.omniportal.app")
  
  const customer = customerStore.getCustomer
  const projects = interfaceStore.getProjects

  if(projects.length == 0 && customer != null ){
    const projects_response = await projectsService.getFromUser(jwt, customer.logto_user_id)
    interfaceStore.setProjects(projects_response)
    interfaceStore.setSelectedProject(customer.default_project ?? projects_response[0].id)
  }
})
</script>

<style>
#layout-main-content .stat-card.card.spinner-wrap {
  height: 100%;
  min-height: 25vw;
}

</style>