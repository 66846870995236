import { defineStore } from "pinia";
import customerService from "../services/customers";

interface ICustomer {
  sub?: string
  name?: string
  role?: string
  _customer?: any
  default_project?: number
}

function initialize(){
  if(localStorage.getItem('customer') === null)
    return null
  else
    return JSON.parse(localStorage.getItem('customer'))
}

export const useCustomerStore = defineStore("customer", {
  state: (): ICustomer => {
    return {
      _customer: initialize()
    }
  },
  getters: {
    getCustomer: state => {
      return state._customer;
    },
    getId: (state) => {
      let id = null;
      if (state._customer != null)
        id = state._customer.logto_user_id
      return id;
    },
    getName: state => {
      let name = null;
      if (state._customer != null)
        name = state._customer.name
      return name;
    },
    getRole: state => {
      let role = null;
      if(state._customer != null)
        role = state._customer.role;
      return role;
    },
    getDefaultProject: state => {
      return state.default_project
    },
    isAuthenticated: state => state._customer != null
  },
  actions: {
    reset() {
      localStorage.removeItem("customer")
      this._customer = null
    },
    setCustomer(value) {
      localStorage.setItem("customer", JSON.stringify(value))
      this._customer = value;
    },
    async fetchCustomer(jwt: string) {
      try {
        this._customer = await customerService.getMe(jwt)
      }
      catch (error) {
        this._customer = null
        return error
      }
    }
  }
});
