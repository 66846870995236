<template lang="">
  <HeaderComponent />
  <slot />
  <FooterComponent />
</template>
<script>
import HeaderComponent from "../layouts/Header/SimpleHeaderComponent.vue"
import FooterComponent from "../layouts/Footer/FooterComponent.vue"

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  }    
}
</script>
<style lang="">
    
</style>