<template lang="">
  <footer class="footer">
    <div class="container">
      <div class="footer-main">
        <a href="#" class="footer-logo">
          <img
            src="/img/logo/logo.png"
            srcset="/img/logo/logo@2x.png 2x"
            alt="logo"
          />
        </a>
        <ul class="footer-contacts">
          <li>
            <a href="#">
              <svg>
                <use xlink:href="/img/svgsprite/svgsprite.svg#letter"></use>
              </svg>
              info@omni-cloud.io
            </a>
          </li>
          <li>
            <a href="#">
              <svg>
                <use xlink:href="/img/svgsprite/svgsprite.svg#phone"></use>
              </svg>
              +31-(0)10-818 71 00
            </a>
          </li>
          <li>
            <a href="#">
              <svg>
                <use xlink:href="/img/svgsprite/svgsprite.svg#linkedin"></use>
              </svg>
              Linkedin
            </a>
          </li>
        </ul>
      </div>
      <div class="footer-bottom">
        <p>
          &copy; 2023 omnicloud - All rights reserved - Design by
          <a href="#">Dutch Blue</a>
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style lang=""></style>
