<template lang="">
  <header class="header">
    <div class="container">
      <div class="header-inner">
        <router-link to="/" class="header-logo">
          <img
            src="/img/logo/logo.png"
            srcset="/img/logo/logo@2x.png 2x"
            alt="logo"
          />
        </router-link>
        <nav class="header-nav">
          <div class="container">
            <ul>
              <li v-if="isAuthenticated">
                <router-link :to="`/overview/${selected_project}`"> Overview </router-link>
              </li>

              <li v-if="isAuthenticated && !isAdmin">
                <router-link :to="`/projects/${customerStore.getId}`"> My Projects </router-link>
              </li>
              <!-- <li class="disabled">
                <router-link to="/customer-portal">
                  Customer portal
                </router-link>
              </li> -->
              <!-- <li class="disabled">
                <a href="#"> Virtuals </a>
              </li> -->
              <li v-if="isAuthenticated">
                <router-link 
                  :to="`/billing/${customerStore.getId}`"
                > Billing </router-link>
              </li>
              <!-- <li class="disabled">
                <router-link to="/support"> Support </router-link>
              </li> -->
            </ul>

            <div class="header-actions">
              <!-- <router-link v-if="!isAuthenticated" to="/signup">
                <button
                  class="header-btn"
                  id="login-btn"
                >
                  Signup
                </button>
              </router-link> -->

              <button
                v-if="!isAuthenticated"
                @click="onClickSignIn"
                class="header-btn"
                id="login-btn"
              >
                Login
              </button>

              <router-link 
                v-if="isAuthenticated && isAdmin"
                to="/admin" 
                class="header-btn"
                id="admin-btn"  
              >
                Admin
              </router-link>

              <router-link 
                v-if="isAuthenticated"
                to="/my-account" 
                class="header-btn"
                id="my-account-btn"  
              >
                My account
              </router-link>
              <button
                v-if="isAuthenticated"
                @click="onClickSignOut"
                class="header-btn"
              >
                Log out
              </button>
            </div>
          </div>
        </nav>
        <div class="hamburger hamburger--spin">
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="sub-header">
      <div class="container">
        <div class="sub-header-inner">
          <ul class="sub-header-menu">
            <li>
              <router-link to="/development-point" > Development Points </router-link>
            </li>
          </ul>
          <!-- <form action="#" class="sub-header-search">
            <svg>
              <use xlink:href="/img/svgsprite/svgsprite.svg#search"></use>
            </svg>
            <input type="text" placeholder="Search" />
          </form> -->
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { useCustomerStore } from "../../stores/customer";
import { useInterfaceStore } from '@/stores/interface'
import { useLogto } from "@logto/vue";
import { storeToRefs } from 'pinia'
import { getUri } from '../../utils'
import { inject, computed } from "vue";


const customerStore = useCustomerStore();
const interfaceStore = useInterfaceStore();
const config = inject('runtimeConfiguration')

const { selected_project } = storeToRefs(interfaceStore)

const { signOut, signIn, getAccessToken } = useLogto();
const onClickSignIn = () => signIn(getUri(
  config.LOGTO_SIGNIN_CALLBACK || '/callback'
));

const onClickSignOut = () => {
  customerStore.reset();
  signOut(getUri(
    config.LOGTO_SIGNOUT_REDIRECT || '/'
  ));
};

const isAuthenticated = customerStore.isAuthenticated

const isAdmin = computed(() => {
  const customer = customerStore._customer;
  if(!customer || customer.role == undefined)
    return false;
  else
    return customer.role == "admin";
})

</script>