<template>
  <component :is="layout">
    <slot />
  </component>
</template>
<script setup>
import { computed } from 'vue'
import { useRoute } from "vue-router";

const defaultLayout = "LayoutDefault";

const route = useRoute();

const layout = computed(() => {
  const name = route.meta?.layout;
  const layout = name || defaultLayout;
  return layout;
})
</script>
