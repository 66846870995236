<template>
  <HeaderComponent />
    <slot />
  <FooterComponent />
</template>
  
<script>
import HeaderComponent from "./Header/HeaderComponent.vue";
import FooterComponent from "./Footer/FooterComponent.vue";

export default {
  name: "LayoutDefault",
  components: {
    HeaderComponent,
    FooterComponent,
  },
};
</script>
  