import instance from "./config";

interface ICustomerCreatePayload {
  customer_form: {
    name: string
    username: string
    email: string
    password_one: string
    password_two: string
    phonenumber: string
  }
  payment_method_form: {
    name: string
    cc_number: string
    exp_month: number
    exp_year: number
    cvc: number
  }
}

export default {
  create: async function (payload: ICustomerCreatePayload): Promise<any> {
    try {
      const { data } = await instance.post("/customers/create", payload)
      return data
    } 
    catch (error) {
      console.log(error)
    }
  },
  listAll: async function (jwt) {
    try {
      const response = await instance.get(`/customers/`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  get: async function (jwt, id) {
    try {
      const response = await instance.get(`/customers/${id}`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getMe: async function (jwt: string): Promise<any> {
    try {
      const { data } = await instance.get("/customers/me", {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return data.data;
    } catch (error) {
      return null;
      console.log(error);
    }
  },
  update: async function (jwt, payload) {
    try {
      const response = await instance.put("/customers", payload, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
};
